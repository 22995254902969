import React from "react";
import { ThemeWrapper, Themes } from "@trussle/external-components";
import "./index.scss";
import { LoggedInStatus } from "./src/components/LoggedInStatus";

export const wrapRootElement = ({ element }) => (
  <ThemeWrapper theme={Themes.TRUSSLE_V3}>
    <LoggedInStatus>
      <div className="theme-reskin">
        { element }
      </div>
    </LoggedInStatus>
  </ThemeWrapper>
);
