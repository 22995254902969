module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Better.co.uk","short_name":"Better.co.uk","background_color":"#d9ffd6","theme_color":"#073126","theme_color_in_head":false,"display":"standalone","lang":"en-GB","icon":"./static/images/favicon-v2.png","crossOrigin":"use-credentials","include_favicon":true,"legacy":true,"cache_busting_mode":"query","cacheDigest":"843209f45de45130f2de2cd4f88f31fe"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
