import {
  ApolloClient, InMemoryCache, ApolloProvider,
} from "@apollo/client";
import React from "react";

export const PUBLIC_API_GRAPHQL_ENDPOINT = `${PUBLIC_API_HOST}/v1/graphql`;
export const PUBLIC_API_GRAPHQL_UNAUTHENTICATED_ENDPOINT = `${PUBLIC_API_HOST}/v1/graphql-unauthenticated`;

const apolloClient = new ApolloClient({
  uri: PUBLIC_API_GRAPHQL_ENDPOINT,
  credentials: "include",
  cache: new InMemoryCache(),
});

export const apolloClientUnauthenticated = new ApolloClient({
  uri: PUBLIC_API_GRAPHQL_UNAUTHENTICATED_ENDPOINT,
  cache: new InMemoryCache(),
});

export const Graphql: React.FC<{children: React.ReactNode}> = ({ children }) => <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
